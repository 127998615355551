html,
body {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-size: 75%; }

html > body div.ui.bottom.right.popup {
  padding: 0px;
  border-radius: 0.66rem; }

#root {
  display: flex;
  min-height: 100%;
  background: #f6f7f7; }

html > body form.ui.form div.react-date-picker__button__input {
  padding-left: 0.33rem; }

html > body form.ui.form div.react-date-picker__button__input input {
  border: none;
  padding: 0px; }
